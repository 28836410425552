import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import Layout from '../../components/Layout'
import PricingPage from '../../hanzo/pages/pricing'

const pricing: React.FC = () => {
    const data = useStaticQuery(graphql`
        query PricingSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Product_Pricing" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout
            newsletter={false}
            linkColor={'dark'}
            isTransparent={true}
            dataLayer={{ event: 'product_pricing_seen' }}>
            <HeaderSEO pageSEO={pageSEO} />
            <PricingPage />
        </Layout>
    )
}

export default pricing
