import React, { SVGAttributes, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { materialOceanic } from 'react-syntax-highlighter/dist/esm/styles/prism'

function CopyToClipboardIcon({
    fill = 'currentColor',
    width = '1em',
    height,
    ...props
}: SVGAttributes<SVGElement>) {
    return (
        <svg
            height={height}
            width={width}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <g fill={fill}>
                <rect
                    height='11'
                    width='11'
                    fill='none'
                    stroke={fill}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    x='0.5'
                    y='0.5'
                />
                <polyline
                    fill='none'
                    points='13.5 4.5 15.5 4.5 15.5 15.5 4.5 15.5 4.5 13.5'
                    stroke={fill}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}

function TickIcon({
    fill = 'currentColor',
    width = '1em',
    height,
    ...props
}: SVGAttributes<SVGElement>) {
    return (
        <svg
            height={height}
            width={width}
            viewBox='0 0 16 16'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <g fill={fill} strokeMiterlimit='10'>
                <circle
                    cx='8'
                    cy='8'
                    fill='none'
                    r='7.5'
                    stroke={fill}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <polyline
                    fill='none'
                    points='4.5,7.5 7,10 12,5 '
                    stroke={fill}
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </svg>
    )
}

const ThemedCodeTag = ({ children, ...props }: { children: React.ReactNode }) => {
    return (
        <code
            {...props}
            className='text-white font-mono text-wrap'
            style={{ background: 'transparent' }}>
            {children}
        </code>
    )
}

const ThemedPreTag = ({ children, ...props }: { children: React.ReactNode }) => {
    return (
        <pre {...props} style={{ background: 'rgb(var(--color-bw-black))' }}>
            {children}
        </pre>
    )
}

const CodeBlock = ({
    text,
    language = 'bash',
    enableCopy = true,
    theme = materialOceanic,
    dataLayer = {},
}: {
    text: string
    language?: string
    enableCopy?: boolean
    theme?: any
    dataLayer?: object
}) => {
    const [isCopied, setIsCopied] = useState(false)

    const handleCopy = () => {
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false)
        }, 1500)
        if (typeof window !== 'undefined' && window.dataLayer) {
            window.dataLayer.push({
                event: 'code_snippet_copied',
                ...dataLayer,
            })
        }
    }

    return (
        <div className='relative group'>
            <SyntaxHighlighter
                language={language}
                className='rounded-2xl'
                wrapLongLines
                style={theme}
                customStyle={{
                    color: 'rgb(var(--color-bw-white))',
                    fontFamily: 'Jet Brains Mono',
                }}
                CodeTag={ThemedCodeTag}
                PreTag={ThemedPreTag}>
                {text}
            </SyntaxHighlighter>

            {enableCopy && (
                <CopyToClipboard onCopy={handleCopy} text={text}>
                    <button
                        type='button'
                        aria-label='Copy to Clipboard Button'
                        className='absolute top-3 right-3 w-5 h-5 bg-transparent border-none cursor-pointer focus:outline-none hover:bg-grey-20 rounded-full color-white z-10 inline-flex items-center justify-center group-hover:opacity-100 opacity-0 transition-opacity duration-300 text-white'>
                        {isCopied ? <TickIcon /> : <CopyToClipboardIcon />}
                    </button>
                </CopyToClipboard>
            )}
        </div>
    )
}

export default CodeBlock
